<div class="container-box">
    <div class="bottom15 flex">
        <div>
            <div class="bottom5">From Date</div>
            <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="From Date" [(ngModel)]="fromDate">
            </nz-date-picker>        
        </div>
        <div>
            <div class="bottom5">To Date</div>
            <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="To Date" [(ngModel)]="toDate">
            </nz-date-picker>        
        </div>
    </div>
    <div class="actions">
        <button nz-button [disabled]="!fromDate || !toDate" (click)="onBtnExport()" nzType="primary" [nzLoading]="downloading">Export</button>
    </div>    
</div>

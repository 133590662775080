<div class="container-box">
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Driver Payment</div>
      <div class="box-tools">
        <ic-driver-report></ic-driver-report>
      </div>
    </div>
  </div>
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Linehaul Payment</div>
      <div class="box-tools">
        <ic-linehaul-report></ic-linehaul-report>
      </div>
    </div>
  </div>
</div>

<div class="container-box top20">
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Customer Payment</div>
      <div class="box-tools">
        <ic-customer-report></ic-customer-report>
      </div>
    </div>
  </div>
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Aritzia Billing Statement</div>
      <div class="box-tools">
        <aritzia-billing-statement></aritzia-billing-statement>
      </div>
    </div>

  </div>
</div>
<div class="container-box top20">
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Walmart Invoice Statement</div>
      <div class="box-tools">
        <walmart-invoice-statement></walmart-invoice-statement>
      </div>
    </div>
  </div>
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Crossdock Shipment report</div>
      <div class="box-tools">
        <xdock-shipment-report></xdock-shipment-report>
      </div>
    </div>
  </div>
</div>

<div class="container-box top20">
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Customer Billing Statement V2</div>
      <div class="box-tools">
        <customer-billing-statement></customer-billing-statement>
      </div>
    </div>
  </div>
</div>
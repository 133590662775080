import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { CustomerBillingStatementConfirm } from "./confirm-dlg";
import moment from "moment";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "customer-billing-statement",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class CustomerBillingStatement extends BaseComponent {
  constructor() {
    super();
  }

  public filter = {
    clientId: null,
    fromDate: moment().toDate(),
    toDate: moment().toDate()
  }
  public loading = false;

  get needUpdate() {
    return !!this.filter.clientId && !!this.filter.toDate && !!this.filter.fromDate;
  }

  public onBtnExport() {
    const data: any = {}
    if (this.filter.fromDate) {
      const fromDate = DateUtil.toBeginOfDay(this.filter.fromDate);
      data.fromDate = DateUtil.convertLocalTime(fromDate, 'America/New_York')?.toISOString();
    }
    if (this.filter.toDate) {
      const toDate = DateUtil.toEndOfDay(this.filter.toDate);
      data.toDate = DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString();
    }
    DialogService.openDialog(CustomerBillingStatementConfirm, {
      replaceWrapClassName: true,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: {
        clientId: this.filter.clientId,
        fromDate: data.fromDate,
        toDate: data.toDate,
      },
      nzClassName: "modal-fullscreen modal-no-padding",
    });
  }

  getApiListDataClientForFilter() {
    return `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`;
  }

}

<div class="container-box">
  <div class="filter">
    <nz-row [nzGutter]="[8,8]">
      <nz-col [nzXs]="24">
        <select-by-searching [version]="2" [(ngModel)]="filter.clientId" [showSubClient]="true"
         [apiUrl]="getApiListDataClientForFilter()"
          placeholder="Select Customer" dropdownClassName="dispatch-filter-customer" class="flex1">
        </select-by-searching>
      </nz-col>
      <nz-col [nzXs]="12">
        <div class="bottom5">From Date</div>
        <nz-input-group nzAddOnAfter="EST">
          <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="From Date" [(ngModel)]="filter.fromDate">
          </nz-date-picker>
        </nz-input-group>
      </nz-col>
      <nz-col [nzXs]="12">
        <div class="bottom5">To Date</div>
        <nz-input-group nzAddOnAfter="EST">
          <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="To Date" [(ngModel)]="filter.toDate">
          </nz-date-picker>
        </nz-input-group>
      </nz-col>
    </nz-row>
  </div>
  <div class="actions">
    <button nz-button [disabled]="!needUpdate" (click)="onBtnExport()" nzType="primary" [nzLoading]="loading">Export</button>
  </div>
</div>
<div class="container-box">
  <div class="header-box">
    <span *ngIf="order?.warpId" class="order-id">Order {{showOrderCode(order)}}</span>
    <a style="margin-left: 15px;" [routerLink]="[routeAdminShipmentList, order?.id]" target="_blank">View Details</a>
  </div>
  <div class="shipments">
    <nz-tabset nzSize="small" nzType="card">
      <nz-tab *ngFor="let shipment of shipments" nzSize="small" [nzTitle]="showShipmentCode(shipment)">
        <ng-template nz-tab>
          <pod-shipment-detail-form [shipment]="shipment"></pod-shipment-detail-form>
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
</div>